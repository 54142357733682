import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default class SkeletonCards extends React.Component {

  render(){
    return(
      <React.Fragment >
        <div className="results-header caption">
          <Skeleton />
        </div>
        <div className="col col-lg-9 tab-container">
          <p className="tab-category"><Skeleton /></p>
          <h4 className="tab-heading"><Skeleton /></h4>
          <p className="tab-description"><Skeleton /></p>
          <Skeleton count={3} />
        </div>
      </React.Fragment >
    );
  }
}

