import React from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
var langCode = 'en';
var RecentSearch = [];
const cookieName = process.env.REACT_APP_AVIATION_COOKIE_NAME;

class GERelatedSearchResultsAviation extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      recentSearchLabel: 'Recent searches',
      RecentSearch: ''
    };

    if (window.xprops){
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  componentDidMount(){
    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        translateData(langCode, this.state.recentSearchLabel)
        .then((data) => {
          this.setState({ recentSearchLabel: data })
        });
      })();
    }
  }

  SelectRecent = (event) => {
    this.props.SelectRecent(event.currentTarget.textContent);
  }

  render() {
    let p = cookies.get(cookieName);
    if (p){
      RecentSearch = p.reverse().map((item, i) => (
        <li key = {i} >
          <span onClick = {this.SelectRecent}>{item}</span>
        </li>
      ));
    }

    return (
      RecentSearch.length > 0 ?
        <div id = "related-search-container" className = "col-lg-4 col-md-4 col-12 px-1">
          <div className = "related-search">{ this.state.recentSearchLabel }</div>
          <div className = "related-search-list">
            <ul>
              {RecentSearch}
            </ul>
          </div>
        </div>
      :
      null
    );
  }
}

export default GERelatedSearchResultsAviation
