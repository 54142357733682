import React from 'react';
const { REACT_APP_AVIATION_SEARCH_TYPE } = process.env;
var searchType = '';
var langCode = 'en';

export default class GECloseComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      closeBtnText: 'CLOSE SEARCH',
      LoadingData: false
    }

    if (window.xprops){
      if (window.xprops.props.GESearchType) {
        searchType = window.xprops.props.GESearchType;
      }
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
   return {
    closeBtnText: props.closeBtnText
   }
  }

  componentDidMount(){
    this.setState({ LoadingData: this.props.DataLoaded});

    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        translateData(langCode, this.state.closeBtnText)
        .then((data) => {
          this.setState({ closeBtnText: data })
        });
      })();
    }
  }

  CloseComponent(){
    this.props.closeSearch();
  }

  render(){
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      return(
        <i
          id = "close-search-component"
          className = "ficon-close d-none"
          onClick = {() => this.CloseComponent()}
        >
        </i>
      );
    }
    else {
      return(
        <div className = "close-search-app mt-0">
          <div
            id = "close-search-component"
            className = "card-links"
            onClick = {() => this.CloseComponent()}
          >
            <h6>{ this.state.closeBtnText }</h6>
          </div>
        </div>
      );
    }
  }
}
