import React from 'react';
const { REACT_APP_AVIATION_SEARCH_TYPE } = process.env;
var searchType = '';

export default class GESearchResultsPagination extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      GetResponseData:[]
    }
    this.ResponseDataInfo=[];

    if (window.xprops){
      if (window.xprops.props.GESearchType) {
        searchType = window.xprops.props.GESearchType;
      }
    }
  }

  recordPrevious(pagecount, domain, sortBy){
    this.props.previous(pagecount, domain, sortBy);
  }

  recordNext(pagecount, domain, sortBy){
    this.props.next(pagecount, domain, sortBy);
  }

  render(){
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      return(
        this.props.totalpages > 0  ?
          <div
            className="ge-btn button-primary-light button-icon btn-plus-icon custom-btn"
            role="button"
            onClick={() => this.recordNext(
                this.props.data.info.page.current_page,
                this.props.domainfilter,
                this.props.selectedSortOption
              )}
          ><a>Load more</a></div>
        :
        null
      );
    }
    else {
      return(
        this.props.totalpages > 0  ?
          <div className="col-12 col-lg-6">
            <div className="pagination-wrapper">
              <ul className="pagination pagination-lg">
                <li className="page-item">
                  <span className="page-link icon ficon-sm-arrow-lft"
                    onClick={() => this.recordPrevious(
                      this.props.data.info.page.current_page,
                      this.props.domainfilter,
                      this.props.selectedSortOption
                    )}
                  >
                  </span>
                </li>
                <li className="page-item result-summary mx-auto">
                  <h5 className=".body-3 ">
                    { this.props.current_page } / { this.props.totalpages }
                  </h5>
                </li>
                <li className="page-item">
                  <span className="page-link icon ficon-sm-arrow-rt"
                    onClick={() => this.recordNext(
                      this.props.data.info.page.current_page,
                      this.props.domainfilter,
                      this.props.selectedSortOption
                    )}
                  >
                  </span>
                </li>
              </ul>
            </div>
          </div>
        :
        null
      );
    }
  }
}

