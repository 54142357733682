import React from 'react';
import Skeleton from 'react-loading-skeleton';
import aviationDomainFilters from './domainFiltersAviation.json';
var langCode = 'en';

export default class GESearchDomainFilter extends React.Component {

  constructor(props) {
    super(props);
    this.STresponseData = [];
    this.Dataloaded = false;
    this.domainFilters = aviationDomainFilters;
    this.checkboxesRef = this.domainFilters.map(index => React.createRef());

    this.state = {
      filterCategoryLabel: 'Category:',
      GetResponseData: [],
      resultbydomain: null,
      STresponseData: [],
      Dataloaded: false,
      selectedSortOption: 'relevance',
      itemsPerPage: null,
      selectedFilters: []
    }

    if (window.xprops){
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
   return {
    itemsPerPage: props.itemsPerPage, // update state with this
    selectedSortOption: props.selectedSortOption,
    selectedFilters: props.selectedFilters
   }
  }

  componentDidUpdate(){
    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        translateData(langCode, this.state.filterCategoryLabel)
        .then((data) => {
          this.setState({ filterCategoryLabel: data })
        });
      })();
    }

    if (this.props.STresponseData.records){
      if (this.state.GetResponseData !== this.props.STresponseData.records.page){
        this.getDomainInfo();
        this.setState({GetResponseData: this.props.STresponseData.records.page});
      }
    }
  }

  getDomainInfo(){
    var searchURL = "/resultsdomain-aviation?keywords=" + encodeURIComponent(this.props.searchtext.replace(/[^\p{L}\p{N}\p{Sc}_@&.+\- ]/gui, '')) + "&perpage=" + this.props.itemsPerPage + "&currentpage=1";
    fetch(searchURL)
    .then(response => response.json())
    .then((data) => {
      this.STresponseData = data;
      this.setState({Dataloaded: true})
    }).catch(error => console.log('error', error));
  }

  setSortOption = (event) => {
    this.setState({selectedSortOption: event.target.value});
    this.props.setSortOption(event.target.value);
  }

  setDomainFilters = (event) => {
    var filters = this.state.selectedFilters;

    if (event.target.checked) {
      if (!filters.includes(event.target.value)) {
        filters.push(event.target.value);
      }
    }
    else {
      if (filters.includes(event.target.value)) {
        const index = filters.indexOf(event.target.value);
        filters.splice(index, 1);
      }
    }
    this.setState({ selectedFilters: filters });
    this.props.setDomainFilters(event.target.value, event.target.checked);
  };

  clearDomainFilters = () => {
    this.checkboxesRef.forEach((checkbox) => {
      checkbox.current.checked = false;
    });

    this.setState({ selectedFilters: [] });
    this.props.clearDomainFilters();
  }

  showSearchResults = (e) => {
    e.preventDefault();
    this.props.showSearchResults(e);
  }

  render(){
    var p = [];

    if (this.state.Dataloaded === true){
      if (this.STresponseData){
        p = this.STresponseData.info.page.facets["domain-identifier"];
      }
    }

    return(
      this.state.Dataloaded === true ?
        this.STresponseData.info && this.STresponseData.info.page.total_result_count > 0 ?
          <React.Fragment>
            <div className = "refine-title">Refine{/* <span className = "d-sm-none d-md-none d-lg-inline">search</span>*/}</div>
            <form className = "form-fields" onSubmit = {this.formSubmit}>
              <div className = "radio-buttons">
                <div className = "row d-flex gx-0 align-items-end me-3">
                  <div className = "col float-start sorting-title">Sort by:</div>
                  <div className = "col d-flex d-sm-flex d-md-none justify-content-end float-end">
                    <button
                      className = "ge-btn clear-btn"
                      role = "button"
                      id = "clearall"
                      disabled = {this.state.selectedFilters.length > 0 ? false : true}
                      onClick = {this.clearDomainFilters}
                    >Clear</button>
                  </div>
                </div>
                <div className = "form-radio d-flex justify-content-start align-items-center">
                  <label className = "form-check-label" htmlFor = "sort-relevance">
                    Relevance
                    <input
                      type = "radio"
                      name = "sort"
                      id = "sort-relevance"
                      value = "relevance"
                      onChange = {this.setSortOption}
                      checked = {this.state.selectedSortOption === "relevance"}
                    />
                  <span className = "radio-checkmark" ></span>
                  </label>
                </div>
                <div className="form-radio d-flex justify-content-start align-items-center">
                  <label className="form-check-label" htmlFor = "sort-newest">
                    Newest
                    <input
                      type = "radio"
                      name = "sort"
                      id = "sort-newest"
                      value = "newest"
                      onChange = {this.setSortOption}
                      checked = {this.state.selectedSortOption === "newest"}
                    />
                    <span className = "radio-checkmark" ></span>
                  </label>
                </div>
              </div>
              <div className = "check-boxes">
                <div className = "check-boxes-title">{ this.state.filterCategoryLabel }</div>
                {
                  this.domainFilters.length > 0 ?
                    this.domainFilters.map((item, index) => (
                      <label className = "checkbox-container" htmlFor = {`checkbox-${item.domainFilter}`} disabled = {p[item.domainFilter] ? false : true}>
                        {item.label}
                        <input
                          ref = {this.checkboxesRef[index]}
                          type = "checkbox"
                          name = "category"
                          value = {item.domainFilter}
                          id = {`checkbox-${item.domainFilter}`}
                          checked = {this.state.selectedFilters.includes(item.domainFilter)}
                          onChange = {this.setDomainFilters}
                          disabled = {p[item.domainFilter] ? false : true}
                        />
                        <span className = "checkmark svg-icon-tick"></span>
                        <span className = "label-count">({p[item.domainFilter] ? p[item.domainFilter] : 0 })</span>
                      </label>
                    ))
                  :
                  null
                }
              </div>
              <div className = "apply-btn d-md-none">
                <button
                  className = "btn-without-link ge-btn button-primary-light button-icon svg-icon-tick"
                  role = "button"
                  id = "btn-apply"
                  onClick = {this.showSearchResults}
                >Apply</button>
              </div>
            </form>
          </React.Fragment>
          :
          null
        :
        <div className = "check-boxes">
          <div className = "check-boxes-title">{ this.state.filterCategoryLabel }</div>
          <Skeleton count = {3} />
        </div>
    );
  }
}


