import React from 'react';
import ReactDOM from 'react-dom';
import GESearchResults from './geSearchResult'
import GEQuicklinksdata from './geQuicklinksData'
import GECloseComponent from './geCloseComponent';
import GEExternalLinksHeader from './geExternalLinksHeader';
import GESearchLabelHeader from './geSearchLabelHeader'
import {Menu,MenuItem,Highlighter, Typeahead}  from 'react-bootstrap-typeahead';
import data from './keywords.json';
import data_aviation from './keywordsAviation.json';
import GEBackComponent from './geBackComponent';
import './index.css';
import './unified_app.css';

const {REACT_APP_AVIATION_SEARCH_TYPE} = process.env;

var searchType = '';
var langCode = 'en';

class GEForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchAllGELabel: 'Search all GE',
      searchAllGEAerospaceLabel: 'Search GE Aerospace',
      suggestionsGELabel: 'Suggestions within GE.com',
      suggestionsGEAerospaceLabel: 'Suggestions within GE Aerospace',
      searchtext: '',
      domainfilter: '1',
      searchvalue: '',
      keywords: '',
      selected: [],
      DataLoaded : false
    };
    this.searchedText = "";
    this.displayAutosuggest = true;
    this.btnExecuteSearch = React.createRef();
    this.btnCloseSearch = React.createRef();

    if (window.xprops){
      if (window.xprops.props.GESearchType) {
        searchType = window.xprops.props.GESearchType;
      }

      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }

      if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
        (async () => {
          // Dynamically imported css (runtime)
          import('./aerospace_app.css').then().catch(() => {});
        })();
        var styleURL = document.getElementById('ge-search-stylesheet').href;
        document.getElementById('ge-search-stylesheet').href = styleURL.replace("ge_unified.style.css", "ge_aero_unified.style.css");
        document.body.setAttribute("data-type", "ge-aerospace");
        this.toggleSearchOverlayHeight(false);
      }
    }
  }

  componentDidMount(){
    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
          translateData(langCode, this.state.searchAllGEAerospaceLabel)
          .then((data) => {
            this.setState({ searchAllGEAerospaceLabel: data })
          });

          translateData(langCode, this.state.suggestionsGEAerospaceLabel)
          .then((data) => {
            this.setState({ suggestionsGEAerospaceLabel: data })
          });
        }
        else {
          translateData(langCode, this.state.searchAllGELabel)
          .then((data) => {
            this.setState({ searchAllGELabel: data })
          });

          translateData(langCode, this.state.suggestionsGELabel)
          .then((data) => {
            this.setState({ suggestionsGELabel: data })
          });
        }
      })();
    }

    this.getKeywords();
    this.resizeFormComponent(true);

    if (window.xprops){
      if (window.xprops.props.UnifiedSearchDisplayBackButton === true){
        ReactDOM.render(<GEBackComponent
          HistoryBackSearch = {this.HistoryBackSearch.bind(this)}
          customMargin = {0} />,
          document.getElementById('ge-close-component')
        );
      }
      else{
        if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
          ReactDOM.render(<GECloseComponent
            ref = {this.btnCloseSearch}
            closeSearch = {this.closeSearch.bind(this)}
            closeBtnText = "Close"
            onClick = {this.executeCloseSearch}
            customMargin = {0} />,
            document.getElementById('ge-close-component')
          );
        }
        else {
          ReactDOM.render(<GECloseComponent
            ref = {this.btnCloseSearch}
            closeSearch = {this.closeSearch.bind(this)}
            closeBtnText = "CLOSE SEARCH"
            onClick = {this.executeCloseSearch}
            customMargin = {0} />,
            document.getElementById('ge-close-component')
          );
        }
      }

      if (window.xprops.props.UnifiedSearchKeyword){
        this.LoadParams(window.xprops.props.UnifiedSearchKeyword);
      }
      else{
        if (document.getElementById('ge-form-wrapper')) {
          ReactDOM.render(<GEQuicklinksdata
            hideRecent = {false}
            initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
            SelectRecent = {this.SelectRecent.bind(this)}
            SelectPopularSearch = {this.SelectPopularSearch.bind(this)} />,
            document.getElementById('ge-search-component-related')
          );
        }
      }
    }
    else{
      if (document.getElementById('ge-form-wrapper')) {
        ReactDOM.render(<GEQuicklinksdata
          hideRecent = {false}
          initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
          SelectRecent = {this.SelectRecent.bind(this)}
          SelectPopularSearch = {this.SelectPopularSearch.bind(this)} />,
          document.getElementById('ge-search-component-related')
        );
      }
    }

    window.addEventListener("message", this.handleMessageEvents);
  }


  handleMessageEvents = (event) => {
    event.stopPropagation();
    if (event.data === "HideComponentOverlay") {
      const mouseEvent = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
      });

      if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
        var closeSearchElem = document.querySelector("#ge-close-component i#close-search-component");
      }
      else {
        var closeSearchElem = document.querySelector("#ge-close-component div#close-search-component");
      }

      if (closeSearchElem) {
        closeSearchElem.dispatchEvent(mouseEvent);
      }
    }
    else if (event.data === "FocusComponentOverlay") {
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
      var searchInput = document.querySelector("input#ge-search-input");
      if (searchInput) {
        searchInput.focus();
      }
    }
    else {
      return;
    }
  }

  componentDidUpdate(prevProps, prevState){
    document.onreadystatechange = function () {
      if (document.readyState === "complete") {
        if (window.xprops){
          window.xprops.props.EnableSearch();
        }
      }
    }

    if (this.state.DataLoaded === true) {
      this.renderSTresults(this.searchedText);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessageEvents);
  }

  closeSearch(){
    this.selected = [];
    this.setState({searchtext: "" });
    this.setState({keywords: "" });
    this.setState({searchvalue: "" });
    this.searchedText = "";
    this.componentDidMount();

    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
    ReactDOM.unmountComponentAtNode(document.getElementById('external-search'));

    const instance = this._typeahead.getInstance()
    instance.clear()

    this.resizeFormComponent(true);
    ReactDOM.render(<GEQuicklinksdata
      hideRecent = {false}
      initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
      SelectRecent = {this.SelectRecent.bind(this)}
      SelectPopularSearch = {this.SelectPopularSearch.bind(this)}/>,
      document.getElementById('ge-search-component-related')
    );

    var p = document.querySelectorAll(".GEInputbox input");
    if (p[0]){
      p[0].value = '';
      p[0].disabled = false;
    }

    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.toggleSearchOverlayHeight(false);
    }

    window.xprops.props.closeSearchComponent();
  }

  resizeFormComponent(useWindowHeight){
    var geFormheight = document.getElementById("ge-form-wrapper");

    if (window.xprops){
      if (useWindowHeight === true){
        geFormheight.style.height = (window.xprops.props.InitialHeight) + "px";
      }
      else{
        geFormheight.removeAttribute("style");
      }
    }
  }

  async getKeywords() {
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.setState({keywords: data_aviation });
    }
    else {
      this.setState({keywords: data });
    }
  }

  getSolarData(searchstring) {
    document.getElementById('ge-form-wrapper').classList.add("nosolarheader");
    this.searchedText = searchstring;
    this.setState({DataLoaded: true});
    this.enableInputbox();
  }

  removeAutoSuggest(){
    this.displayAutosuggest = false;
    if (document.getElementById('ge-search-input')) {
      document.getElementById('ge-search-input').classList.add("hide-autosuggest");
    }
  }

  renderSTresults(searchstring){
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      ReactDOM.render(<GESearchLabelHeader searchResultsLabel="" />,
        document.getElementById('search-results-label')
      );
      this.resizeFormComponent(false);

      ReactDOM.render(<GESearchResults
          domainfilter = {this.state.domainfilter}
          searchtext = {searchstring}
          SelectRecent = {this.SelectRecent.bind(this)}
          SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
          DataLoaded = {this.state.DataLoaded}
        />,
        document.getElementById('ge-stresults')
      );
      this.toggleSearchOverlayHeight(true);
    }
    else {
      ReactDOM.render(<GESearchLabelHeader searchResultsLabel="SEARCH RESULTS"/>,
        document.getElementById('search-results-label')
      );
      this.resizeFormComponent(false);

      ReactDOM.render(<GEExternalLinksHeader searchtext = {searchstring} />,
        document.getElementById('external-search')
      );

      ReactDOM.render(<GESearchLabelHeader searchResultsLabel="SEARCH RESULTS"/>,
        document.getElementById('search-results-label')
      );

      ReactDOM.render(<GESearchResults
        domainfilter = {this.state.domainfilter}
        searchtext = {searchstring}
        SelectRecent = {this.SelectRecent.bind(this)}
        SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
        DataLoaded = {this.state.DataLoaded}/>,
        document.getElementById('ge-stresults')
      );
    }
  }

  disableInputbox(){
    var p = document.querySelectorAll(".GEInputbox input");

    if (p[0]){
      p[0].disabled = true;
      const instance = this._typeahead.getInstance();
      instance.blur();
    }
  }

  enableInputbox(){
    var p = document.querySelectorAll(".GEInputbox input");

    if (p[0]){
      p[0].disabled = false;
    }
  }

  LoadParams(searchstring) {
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
    this.getSolarData(searchstring);
    this.selected = [];
    this.selected.push(searchstring);
    this.setState({searchtext: searchstring});
  }

  HistoryBackSearch(){
    window.xprops.props.HistoryBack();
  }

  SubmitFormHandler(searchstring) {
    if(window.xprops){
      window.xprops.props.dataLayerPush("user_input", searchstring);
    }
    if (searchstring.length >= 3){
      this.disableInputbox();
      this.removeAutoSuggest();
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
      this.getSolarData(searchstring);
      this.selected = [];
      this.selected.push(searchstring);
      this.setState({searchtext: searchstring});
    }
  }

  toggleSearchOverlayHeight(noRelatedSuggestion) {
    if (noRelatedSuggestion) {
      if (!document.getElementById('ge-form-wrapper').classList.contains("no-related-suggestions")) {
        document.getElementById('ge-form-wrapper').classList.add("no-related-suggestions");
      }
    }
    else {
      if (document.getElementById('ge-form-wrapper').classList.contains("no-related-suggestions")) {
        document.getElementById('ge-form-wrapper').classList.remove("no-related-suggestions");
      }
    }
  }

  geOnBlur = (event) => {
    this.displayAutosuggest = false;
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      var inputSearch = document.querySelector("input#ge-search-input");

      if (inputSearch) {
        if (inputSearch.value === "") {
          this.toggleSearchOverlayHeight(false);
        }
        else {
          this.toggleSearchOverlayHeight(true);
        }
      }
    }
  }

  geOnFocus = (event) => {
    ReactDOM.unmountComponentAtNode(document.getElementById('external-search'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
    ReactDOM.unmountComponentAtNode(document.getElementById('search-results-label'));
    this.resizeFormComponent(true);

    if (event.target.value.length <= 0) {
      ReactDOM.render(<GEQuicklinksdata
        hideRecent = {false}
        initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
        SelectRecent = {this.SelectRecent.bind(this)}
        SelectPopularSearch = {this.SelectPopularSearch.bind(this)}/>,
        document.getElementById('ge-search-component-related')
      );
    }
    else{
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
    }

    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.toggleSearchOverlayHeight(false);
    }
  }

  SelectRecent(selectedRecent){
    if(window.xprops){
      window.xprops.props.dataLayerPush("recent_search", selectedRecent);
    }

    this.disableInputbox();
    this.selected = [selectedRecent];
    this.setState({DataLoaded: false });
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
    this.getSolarData(selectedRecent);
    this.selected.push(selectedRecent);
    this.setState({searchtext: selectedRecent});

    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.toggleSearchOverlayHeight(true);
    }
  }

  SelectPopularSearch(selectedRecent){
    if(window.xprops){
      window.xprops.props.dataLayerPush("popular_search", selectedRecent);
    }

    this.disableInputbox();
    this.selected = [selectedRecent];
    this.setState({DataLoaded: false });
    this.selected.push(selectedRecent);
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
    this.getSolarData(selectedRecent)
    this.setState({searchtext: selectedRecent});

    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.toggleSearchOverlayHeight(true);
    }
  }

  onSelected = (event) => {
    if (event[0]){
      if(window.xprops){
        window.xprops.props.dataLayerPush("auto_complete", event[0]);
      }

      this.selected = [event[0]];
      this.resizeFormComponent(true);
      ReactDOM.unmountComponentAtNode(document.getElementById('external-search'));
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
      this.renderSTresults(event[0]);
      this.setState({searchtext: event[0]});
      this.getSolarData(event[0]);
    }
  }

  keypressed = (event) => {
    this.selected = [event];

    if (event.length >= 3){
      this.resizeFormComponent(true);
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
      ReactDOM.unmountComponentAtNode(document.getElementById('search-results-label'));
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
      ReactDOM.unmountComponentAtNode(document.getElementById('external-search'));
    }
    else if (event.length === 0){
      const instance = this._typeahead.getInstance();
      instance.blur();
      instance.focus();

      ReactDOM.render(<GEQuicklinksdata
        hideRecent = {false}
        initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
        SelectRecent = {this.SelectRecent.bind(this)}
        SelectPopularSearch = {this.SelectPopularSearch.bind(this)} />,
        document.getElementById('ge-search-component-related')
      );
    }
  }

  executeCloseSearch = (event) => {
    event.preventDefault();
    const mouseEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
    });

    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      var closeSearchElem = document.querySelector("#ge-close-component i#close-search-component");
    }
    else {
      var closeSearchElem = document.querySelector("#ge-close-component div#close-search-component");
    }

    if (closeSearchElem) {
      closeSearchElem.dispatchEvent(mouseEvent);
    }
  }

  executeSearch = (event) => {
    event.preventDefault();
    const keyboardEvent = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: true,
      key: "Enter",
      code: "Enter",
      shiftKey: false,
      ctrlKey: false,
      altKey: false,
      keyCode: 13,
      which: 13
    });

    var searchElem = document.querySelector("div.GEInputbox input#ge-search-input[aria-owns='ge-search-input']");
    if (searchElem) {
      searchElem.dispatchEvent(keyboardEvent);
    }
  }


  render() {
//console.log('deployment log: 03/08/2024 - P1');

    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      return (
        this.state.keywords.length > 0 ?
          <form onSubmit = {this.mySubmitHandler}
            ref = "gesearchform"
            className = "container-fluid"
          >
            <div className = "row">
              <div className = "col-lg-8 col-md-8 col-12 offset-md-2">
                <div id = "search-results-label"></div>
                <div className = "GEInputbox" >
                  <h3 className = "search-icon-heading"><div className = "ficon-search float-right"></div></h3>
                  <div className="search-button-container d-block">
                    <button
                      ref = {this.btnExecuteSearch}
                      id = "btn-execute-search"
                      title = "Execute search"
                      className="cir-button cir-dark btn-arrow-right-icon"
                      onClick = {this.executeSearch}
                    ></button>
                  </div>
                  <Typeahead
                    selected = {this.selected}
                    ref = {(ref) => this._typeahead = ref}
                    id = "ge-search-input"
                    inputProps = {{
                      //autofocus: "",
                      id: "ge-search-input",
                      autoComplete: "off",
                      className: "px-0 mx-0 w-100"
                    }}
                    //autoFocus = {true}
                    bsSize = {'lg'}
                    maxResults = {5}
                    minLength = {3}
                    paginate = {false}
                    placeholder = { this.state.searchAllGEAerospaceLabel }
                    options = {this.state.keywords}
                    renderMenu = {(results, menuProps) => (
                      <>
                        <Menu {...menuProps} onKeyDown = {this.onKeyDown}>
                          {results.length > 0 && (<div className = "autosuggest caption">{ this.state.suggestionsGEAerospaceLabel }</div>)}

                          {results.map((result, index) => (
                            <MenuItem
                              className = "suggestion"
                              option = {result}
                              position = {index}
                              key = {index}
                              data-attribute-textval = {result}
                            >
                              <Highlighter search = {menuProps.text}>
                                {result}
                              </Highlighter>
                            </MenuItem>
                          ))}
                        </Menu>
                      </>
                    )}
                    align = {"left"}
                    maxHeight = {'500px'}
                    onFocus = {this.geOnFocus}
                    onBlur = {this.geOnBlur}
                    onInputChange = {this.keypressed}
                    onChange = {this.onSelected}
                    onKeyDown = {(e) => {
                      if (e.keyCode === 13){
                        this.SubmitFormHandler(e.currentTarget.value);
                      }
                      else if (e.keyCode === 27) {
                        this.closeSearch();
                      }
                    }}
                  />
                  <div id = "external-search"></div>
                </div>
              </div>
            </div>
          </form>
        :
          null
      );
    }
    else {
      return (
        this.state.keywords.length > 0 ?
          <form onSubmit = {this.mySubmitHandler} ref = "gesearchform">
            <div className = "row">
              <div className = "col-12">
                <div id = "search-results-label"></div>
                <div className = "GEInputbox" >
                  <h3><div className = "ficon-search float-right"></div></h3>
                  <Typeahead
                    selected = {this.selected}
                    ref = {(ref) => this._typeahead = ref}
                    id = "ge-search-input"
                    inputProps = {{
                      id: "ge-search-input",
                      autoComplete: "off"
                    }}
                    bsSize = {'lg'}
                    maxResults = {5}
                    minLength = {3}
                    paginate = {false}
                    placeholder = { this.state.searchAllGELabel }
                    options = {this.state.keywords}
                    renderMenu = {(results, menuProps) => (
                      <Menu {...menuProps} onKeyDown = {this.onKeyDown}>
                        {results.length > 0 && (<div className = "autosuggest caption">{ this.state.suggestionsGELabel }</div>)}

                        {results.map((result, index) => (
                          <MenuItem
                            className = "body-1"
                            option = {result}
                            position = {index}
                            key = {index}
                            data-attribute-textval = {result}
                          >
                            <Highlighter search = {menuProps.text}>
                              {result}
                            </Highlighter>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                    align = {"left"}
                    maxHeight = {'500px'}
                    onFocus = {this.geOnFocus}
                    onBlur = {this.geOnBlur}
                    onInputChange = {this.keypressed}
                    onChange = {this.onSelected}
                    onKeyDown = {(e) => {
                      if (e.keyCode === 13){
                        this.SubmitFormHandler(e.currentTarget.value);
                      }
                      else if (e.keyCode === 27) {
                        this.closeSearch();
                      }
                    }}
                  />
                  <div id = "external-search"></div>
                </div>
              </div>
            </div>
          </form>
        :
          null
      );
    }
  }
}

export default GEForm

