import React from 'react';
import GESearchResultsStructure from './geSearchResultDataHTML'
import GESearchDomainFilter from './geSearchDomainFilter'
import GESearchResultsPagination from './geSearchResultsPagination'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const cookieName = process.env.REACT_APP_COOKIE_NAME;


export default class GESearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.STresponse = [];
    this.STresponseInfo = [];
    this.STresponseData = [];
    this.STgetsearchtext = '';
    this.STreportsResults = [];
    this.STnewsResults = [];
    this.domainfilter = null;

    /*********/
    this.alldomaincounts = 0;
    this.gedomaincounts = 0;
    this.gereporstdomaincounts = 0;
    this.genewsroomdomaincounts = 0;
    this.state = {
      STgetsearchtext: '',
      STreportsResults: [],
      STnewsResults: [],
      STallResults: [],
      STdataLoaded: false,
      cookies: [],
    };

    const url = new URL(process.env.REACT_APP_URL);
    this.domainHostname = url.hostname;
  }

  componentDidMount() {
    this.getSTresponse(1, null);
  }

  getSTresponse(pageCount, domainFilter) {
    this.domainfilter = domainFilter;
    var searchURL = "/resultsdomain?keywords=" + encodeURIComponent(this.props.searchtext.replace(/[^\p{L}\p{N}\p{Sc}_@&.+\- ]/gui, '')) + "&currentpage=" + pageCount + "&domainFilter=" + domainFilter;
    fetch(searchURL)
    .then(response => response.json())
    .then((data) => {
      this.STresponseInfo = data.info.page;
      this.STresponseData = data;
      this.STgetsearchtext = this.props.searchtext;
      this.setState({STdataLoaded: true});

      if (this.props.searchtext.length > 0) {
        this.updateSearchCookie(this.props.searchtext);
      }
    })
    .catch(error => console.log('error', error));
  }

  updateSearchCookie(searchtext){
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    this.setState({cookies: cookies.get(cookieName)});
    if (this.state.cookies){
      let relatedSearch = this.state.cookies;
      if (relatedSearch.indexOf(searchtext) === -1) {
        if (relatedSearch.length === 5){
          relatedSearch.shift();
        }
      }
      else {
        relatedSearch.splice(relatedSearch.indexOf(searchtext), 1);
      }
      relatedSearch.push(searchtext);
      cookies.set(cookieName, relatedSearch, {
        path: '/',
        secure: true,
        //httpOnly: true,
        expires: nextYear,
        sameSite: 'none',  //none, strict, lax, true, false
        domain: this.domainHostname,
        partitioned: true
      });
    }
    else {
      let relatedSearch = [];
      relatedSearch.push(searchtext);
      cookies.set(cookieName, relatedSearch, {
        path: '/',
        secure: true,
        //httpOnly: true,
        expires: nextYear,
        sameSite: 'none',  //none, strict, lax, true, false
        domain: this.domainHostname,
        partitioned: true
      });
    }
  }

  FilterbyDomain(domain){
    this.setState({STdataLoaded: false});
    this.getSTresponse(1, domain);
  }

  recordPrevious(pagecount, domain) {
    let IsBOF = pagecount - 1;
    if (IsBOF <= 0){

    }
    else{
      this.setState({STdataLoaded: false});
      this.getSTresponse(pagecount - 1, domain);
    }
  }

  recordNext(pagecount, domain) {
    let IsEOF = pagecount + 1;
    if (IsEOF > this.STresponseInfo.num_pages){
    }
    else{
      this.setState({STdataLoaded: false});
      this.getSTresponse(pagecount + 1, domain);
    }
  }


  SelectPopularSearch(selectedRecent){
    this.setState({STdataLoaded: false});
    this.props.SelectPopularSearch(selectedRecent);
  }

  render() {
    return(
      <div className = "container-fluid-custom">
        <div className = "STresults-wrapper ">
          <div className = "row">
            <div className = "col-lg-5 col-md-5 col-sm-12">
              <GESearchDomainFilter
                domainfilter = {this.domainfilter}
                STresponseData = {this.STresponseData}
                alldomaincounts = {this.alldomaincounts}
                gedomaincounts = {this.gedomaincounts}
                gereporstdomaincounts = {this.gereporstdomaincounts}
                genewsroomdomaincounts = {this.genewsroomdomaincounts}
                FilterbyDomain = {this.FilterbyDomain.bind(this)}
                searchtext = {this.STgetsearchtext}
                Dataloaded = {this.state.STdataLoaded}
              />
            </div>
            <div className = "col-lg-7 col-md-7 col-sm-12">
              <GESearchResultsStructure
                STresponseData = {this.STresponseData}
                domainfilter = {this.domainfilter}
                searchtext = {this.STgetsearchtext}
                SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
                Dataloaded = {this.state.STdataLoaded}
              />
              <div className = "row">
                <GESearchResultsPagination
                  data = {this.STresponseData}
                  next = {this.recordNext.bind(this)}
                  previous = {this.recordPrevious.bind(this)}
                  domainfilter = {this.domainfilter}
                  totalpages = {this.STresponseInfo.num_pages}
                  current_page = {this.STresponseInfo.current_page}
                  searchtext = {this.STgetsearchtext}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
