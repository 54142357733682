import React from 'react';
import GeSearchResultsData from './geSearchResultData';
import GeSearchResultsDataAviation from './geSearchResultDataAviation';
const { REACT_APP_AVIATION_SEARCH_TYPE } = process.env;
var searchType = '';
var langCode = 'en';

class GESearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.state={
      LoadingData: false
    }

    if (window.xprops){
      if (window.xprops.props.GESearchType) {
        searchType = window.xprops.props.GESearchType;
      }
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  componentDidMount(){
    this.setState({LoadingData:this.props.DataLoaded});
  }

  SelectPopularSearch(selectedRecent){
    this.setState({LoadingData: false});
    this.props.SelectPopularSearch(selectedRecent);
  }

  render() {
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      return (
        this.state.LoadingData === true ?
          <GeSearchResultsDataAviation
            domainfilter = {this.props.domainfilter}
            searchtext = {this.props.searchtext}
            SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
          />
        :
          null
      );
    }
    else {
      return (
        this.state.LoadingData === true ?
          <GeSearchResultsData
            domainfilter = {this.props.domainfilter}
            searchtext = {this.props.searchtext}
            SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
          />
        :
          null
      );
    }
  }
}

export default GESearchResults

