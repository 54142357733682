import React from 'react';
import GESearchResultsStructure from './geSearchResultDataAviationHTML'
import GESearchDomainFilter from './geSearchDomainFilterAviation'
import GESearchResultsPagination from './geSearchResultsPagination'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const defaultDomainFilter = '';
const defaultItemsPerPage = 10;
const cookieName = process.env.REACT_APP_AVIATION_COOKIE_NAME;


export default class GeSearchResultsDataAviation extends React.Component {
  constructor(props) {
    super(props);

    this.STresponse = [];
    this.STresponseInfo = [];
    this.STresponseData = [];
    this.STgetsearchtext = '';
    this.STreportsResults = [];
    this.STnewsResults = [];
    this.domainfilter = [];
    this.btnShowRefine = React.createRef();
    this.divShowRefine = React.createRef();
    this.divListSection = React.createRef();
    this.btnCloseSearch = React.createRef();

    /*********/
    this.state = {
      STgetsearchtext: '',
      STreportsResults: [],
      STnewsResults: [],
      STallResults: [],
      STdataLoaded: false,
      selectedSortOption: 'relevance',
      itemsPerPage: defaultItemsPerPage,
      selectedFilters: [],
      cookies: [],
    };

    const url = new URL(process.env.REACT_APP_URL);
    this.domainHostname = url.hostname;
  }

  componentDidMount() {
    this.getSTresponse(this.state.itemsPerPage, 1, null, this.state.selectedSortOption);
  }

  getSTresponse(perPage, pageCount, domainFilter, sortBy = 'relevance') {
    if (!domainFilter) {
      domainFilter = defaultDomainFilter;
    }
    this.domainfilter = domainFilter;
    var searchURL = "/resultsdomain-aviation?keywords=" + encodeURIComponent(this.props.searchtext.replace(/[^\p{L}\p{N}\p{Sc}_@&.+\- ]/gui, '')) + "&perpage=" + perPage + "&currentpage=" + pageCount + "&domainFilter=" + domainFilter;
    if (sortBy != 'relevance') {
      searchURL += '&sortBy=' + sortBy;
    }

    fetch(searchURL)
    .then(response => response.json())
    .then((data) => {
      this.STresponseInfo = data.info.page;
      this.STresponseData = data;
      this.STgetsearchtext = this.props.searchtext;
      this.setState({STdataLoaded: true});
      if (this.props.searchtext.length > 0) {
        this.updateSearchCookie(this.props.searchtext);
      }
    })
    .catch(error => console.log('error', error));
  }

  updateSearchCookie(searchtext){
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);

    this.setState({cookies: cookies.get(cookieName)});
    if (this.state.cookies){
      let relatedSearch = this.state.cookies;
      if (relatedSearch.indexOf(searchtext) === -1) {
        if (relatedSearch.length === 5){
          relatedSearch.shift();
        }
      }
      else {
        relatedSearch.splice(relatedSearch.indexOf(searchtext), 1);
      }
      relatedSearch.push(searchtext);
      cookies.set(cookieName, relatedSearch, {
        path: '/',
        secure: true,
        //httpOnly: true,
        expires: nextYear,
        sameSite: 'none',  //none, strict, lax, true, false
        domain: this.domainHostname,
        partitioned: true
      });
    }
    else {
      let relatedSearch = [];
      relatedSearch.push(searchtext);
      cookies.set(cookieName, relatedSearch, {
        path: '/',
        secure: true,
        //httpOnly: true,
        expires: nextYear,
        sameSite: 'none',  //none, strict, lax, true, false
        domain: this.domainHostname,
        partitioned: true
      });
    }
  }

  recordPrevious(pagecount, domain, sortBy) {
    let IsBOF = pagecount - 1;
    if (IsBOF <= 0){

    }
    else{
      this.setState({STdataLoaded: false});
      this.getSTresponse(this.state.itemsPerPage, pagecount - 1, domain, sortBy);
    }
    document.getElementById('ge-search-result-header').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  recordNext(pagecount, domain, sortBy) {
    let IsEOF = pagecount + 1;
    if (IsEOF > this.STresponseInfo.num_pages){
    }
    else{
      this.setState({STdataLoaded: false});
      this.getSTresponse(this.state.itemsPerPage, pagecount + 1, domain, sortBy);
    }
    document.getElementById('ge-search-result-header').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  SelectPopularSearch(selectedRecent){
    this.setState({STdataLoaded: false});
    this.props.SelectPopularSearch(selectedRecent);
  }

  setSortOption = (sortOption) => {
    this.setState({ selectedSortOption: sortOption });
    this.setState({STdataLoaded: false});
    this.getSTresponse(this.state.itemsPerPage, this.STresponseInfo.current_page, this.state.selectedFilters, sortOption);
  }

  setDomainFilters = (domainFilter, checked) => {
    var filters = this.state.selectedFilters;

    if (checked) {
      if (!filters.includes(domainFilter)) {
        filters.push(domainFilter);
      }
    }
    else {
      if (filters.includes(domainFilter)) {
        const index = filters.indexOf(domainFilter);
        filters.splice(index, 1);
      }
    }

    this.setState({ selectedFilters: filters });
    this.setState({STdataLoaded: false});

    var filterValues = filters.toString().length >= 1 ? filters.toString() : defaultDomainFilter;
    this.getSTresponse(this.state.itemsPerPage, this.STresponseInfo.current_page, filterValues, this.state.selectedSortOption);
  }

  clearDomainFilters = () => {
    this.setState({ selectedFilters: [] });
    this.setState({STdataLoaded: false});

    this.getSTresponse(this.state.itemsPerPage, this.STresponseInfo.current_page, "", this.state.selectedSortOption);
  }

  showSearchResults = (e) => {
    e.preventDefault();
    this.btnCloseSearch.current.click();
  }

  showRefineSection = () => {
    //Getting node element
    const btnNode = this.btnShowRefine.current;
    const divNode = this.divShowRefine.current;
    const divListNode = this.divListSection.current;

    //Adding class to node element
    if (!btnNode.classList.contains("refine-list-mobile")) {
      btnNode.classList.add("refine-list-mobile");
    }

    if (!divNode.classList.contains("refine-list-mobile")) {
      divNode.classList.add("refine-list-mobile");
    }

    divListNode.style.display = "none";
  }

  hideRefineSection = () => {
    const btnNode = this.btnShowRefine.current;
    const divNode = this.divShowRefine.current;
    const divListNode = this.divListSection.current;

    //Remove class from node element
    if (btnNode.classList.contains("refine-list-mobile")) {
      btnNode.classList.remove("refine-list-mobile");
    }

    if (divNode.classList.contains("refine-list-mobile")) {
      divNode.classList.remove("refine-list-mobile");
    }

    divListNode.style.display = "unset";
  }

  setResultsPerPage = (resultsPerPage) => {
    this.setState({ itemsPerPage: resultsPerPage });
    this.setState({STdataLoaded: false});
    this.getSTresponse(resultsPerPage, this.STresponseInfo.current_page, this.state.selectedFilters, this.state.selectedSortOption);
  }

  render() {

    return(
      <div className = "STresults-wrapper container-fluid">
        <button
          ref = {this.btnShowRefine}
          className = "ge-btn button-primary-light btn-without-link refine-search-btn"
          id = "refine-mobile-btn"
          role = "button"
          onClick = {this.showRefineSection}
        >Refine search</button>
        <div className = "refine-list-section row g-0 ">
          <div
            ref = {this.divShowRefine}
            className = "col-12 col-md-4 col-lg-3 refine-section"
            id = "refine-list-mobile"
          >
            <div className = "close-icon me-3">
              <button
                ref = {this.btnCloseSearch}
                className = "cir-button cir-light-10 cir-light btn-close-icon"
                id = "btn-close-icon"
                onClick = {this.hideRefineSection}
              ></button>
            </div>
            <GESearchDomainFilter
              domainfilter = {this.domainfilter}
              STresponseData = {this.STresponseData}
              searchtext = {this.STgetsearchtext}
              Dataloaded = {this.state.STdataLoaded}
              itemsPerPage = {this.state.itemsPerPage}
              selectedSortOption = {this.state.selectedSortOption}
              setSortOption = {this.setSortOption}
              selectedFilters = {this.state.selectedFilters}
              setDomainFilters = {this.setDomainFilters}
              clearDomainFilters = {this.clearDomainFilters}
              showSearchResults = {this.showSearchResults}
            />
          </div>
          <div className = "col-1 d-sm-none d-md-1 col-lg-1 d-md-block"></div>
          <div
            ref = {this.divListSection}
            className = "col-12  col-md-7 col-lg-8 list-section"
          >
            <GESearchResultsStructure
              STresponseData = {this.STresponseData}
              domainfilter = {this.domainfilter}
              searchtext = {this.STgetsearchtext}
              SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
              Dataloaded = {this.state.STdataLoaded}
              itemsPerPage = {this.state.itemsPerPage}
              setResultsPerPage = {this.setResultsPerPage}
            />
            <GESearchResultsPagination
              data = {this.STresponseData}
              next = {this.recordNext.bind(this)}
              previous = {this.recordPrevious.bind(this)}
              domainfilter = {this.domainfilter}
              totalpages = {this.STresponseInfo.num_pages}
              current_page = {this.STresponseInfo.current_page}
              searchtext = {this.STgetsearchtext}
              selectedSortOption = {this.state.selectedSortOption}
            />
          </div>
        </div>
      </div>
    );
  }
}
