import React from 'react';
import GESearchNoResultData from './geSearchNoResultDataAviation';
import SkeletonCards from './geResultSkeletonAviation';
import aviationDomainFilters from './domainFiltersAviation.json';
var CardResults = [];
const options = [
  { label: '10 Per Page', value: '10' },
  { label: '20 Per Page', value: '20' },
  { label: '30 Per Page', value: '30' },
  { label: '40 Per Page', value: '40' },
  { label: '50 Per Page', value: '50' }
];
const defaultItemsPerPage = 10;

export default class GESearchResultsStructure extends React.Component {

  constructor(props) {
    super(props);
    this.ResponseDataInfo = [];
    this.domainFilters = aviationDomainFilters;
    this.domainfilter = "";
    this.domainfilterDesc = "";
    this.searchtext = "";
    this.state = {
      GetResponseData: [],
      LoadingData: false,
      itemsPerPage: defaultItemsPerPage
    };
  }

  static getDerivedStateFromProps(props, state) {
   return {
    itemsPerPage: props.itemsPerPage // update state with this
   }
  }

  componentDidUpdate(){
    if (this.props.Dataloaded === true ){
      if (this.props.STresponseData){
        this.ResponseDataInfo = this.props.STresponseData.info.page;
        this.domainfilter = this.props.domainfilter;
        this.searchtext = this.props.searchtext;
        if (this.state.GetResponseData !== this.props.STresponseData.records.page){
          this.setState({GetResponseData: this.props.STresponseData.records.page});
        }

        this.domainfilterDesc = " across GE Aerospace";

        if (this.domainFilters.length > 0) {
          for (var i = 0; i < this.domainFilters.length; i++) {
            if (String(this.domainfilter) === String(this.domainFilters[i].domainFilter)) {
              this.domainfilterDesc = " within " + this.domainFilters[i].label;
              break;
            }
          }
        }

      }
    }
  }

  setResultsPerPage = (event) => {
    this.setState({itemsPerPage: event.target.value});
    this.props.setResultsPerPage(event.target.value);
  }

  openLinks = (event) => {
    if (event.currentTarget.attributes['data-link-src']){
      window.xprops.props.Openlinks(event.currentTarget.attributes['data-link-src'].value);
    }
  }

  SelectPopularSearch(selectedRecent){
    this.props.SelectPopularSearch(selectedRecent);
  }

  setCardCaption(domainIdentifier) {
    var cardCaption = "GE Aerospace";

    if (this.domainFilters.length > 0) {
      for (var i = 0; i < this.domainFilters.length; i++) {
        if (String(domainIdentifier) === String(this.domainFilters[i].domainFilter)) {
          cardCaption = this.domainFilters[i].label;
          break;
        }
      }
    }

    return <>{cardCaption}</>;
  }


  render(){
    CardResults = this.state.GetResponseData.map((item, i) => (
      <div className="col col-lg-9 tab-container" data-link-src = {item.url} onClick = {this.openLinks}>
        <div className = "container-card-item">
          <p className="tab-category">{this.setCardCaption(item['domain-identifier'])}</p>
          {
            item.highlight.title ?
              <h3
                className="tab-heading"
                dangerouslySetInnerHTML = {{__html: item.highlight.title}}
              />
            :
              <h3 className="tab-heading">{item.title}</h3>
          }
          {item.highlight.body ?
            item.highlight.body.replace(/�/g, "").length > 313 ?
              <p className="tab-description" dangerouslySetInnerHTML =
                {{__html: item.highlight.body.replace(/�/g, "").substring(0, 313) + " ..."}}
              />
            :
              <p className="tab-description" dangerouslySetInnerHTML =
                {{__html: item.highlight.body.replace(/�/g, "")}}
              />
          :
            item.body.replace(/�/g, "").length > 313 ?
              <p className="tab-description" dangerouslySetInnerHTML =
                {{__html: item.body.replace(/�/g, "").substring(0, 313) + " ..."}}
              />
            :
              <p className="tab-description" dangerouslySetInnerHTML =
                {{__html: item.body.replace(/�/g, "").substring(0, 313)}}
              />
          }
        </div>
      </div>
    ));

    return(
      this.props.Dataloaded === false ?
        <SkeletonCards />
      :
        //CardResults.length
        CardResults.length > 0 ?
          <React.Fragment >
            <div className="row mx-0 display-result">
              <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-8 px-0 results">
                <span>Showing {(this.ResponseDataInfo.current_page * this.state.itemsPerPage) - (this.state.itemsPerPage - 1)} - {this.ResponseDataInfo.total_result_count > this.state.itemsPerPage ? this.ResponseDataInfo.current_page * this.state.itemsPerPage : this.ResponseDataInfo.total_result_count} of </span><span className="total-rslt">{this.ResponseDataInfo.total_result_count} results</span>
              </div>
              <div className="col d-sm-none d-lg-none">

              </div>
              <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 px-0 d-flex justify-content-sm-end justify-content-md-start justify-content-lg-end align-items-center pg-slct">
                <span>Displayed results</span>
                <select className="page-selection" id="pagecount" onChange = {this.setResultsPerPage} value={this.state.itemsPerPage}>
                  {options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="document-search-list">
              {CardResults}
            </div>
          </React.Fragment >
        :
          <GESearchNoResultData
            searchtext = {this.searchtext}
            SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
          />
    );
  }
}
