import React from 'react';
import GERelatedSearchResults from './geRelatedSearchResultData';
import GERelatedSearchResultsAviation from './geRelatedSearchResultDataAviation';
import aviationPopularSearches from './popularAviation.json';
const { REACT_APP_AVIATION_SEARCH_TYPE } = process.env;
var quickLinks;
var popularsearch;
var InitialHeight = 0;
var searchType = '';
var langCode = 'en';

export default class GEQuicklinksdata extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      popularSearchesLabel: 'Popular searches',
      quicklinksLabel: 'Quicklinks',
      hits:[]
    }
    this.quicklinks = [];
    this.popularsearch = [];

    if (window.xprops){
      if (window.xprops.props.GESearchType) {
        searchType = window.xprops.props.GESearchType;
      }
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }

    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.popularsearch = aviationPopularSearches;
    }
  }

  componentDidMount() {
    if (window.xprops){
      InitialHeight = window.xprops.props.InitialHeight- 97;
    }
    else{
      InitialHeight = 500;
    }

    this.getQuicklinksdata();

    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        translateData(langCode, this.state.popularSearchesLabel )
        .then((data) => {
          this.setState({ popularSearchesLabel: data })
        });

        translateData(langCode, this.state.quicklinksLabel )
        .then((data) => {
          this.setState({ quicklinksLabel: data })
        });
      })();
    }
  }

  toggleSearchOverlayHeight(noRelatedSuggestion) {
    if (noRelatedSuggestion) {
      if (!document.getElementById('ge-form-wrapper').classList.contains("no-related-suggestions")) {
        document.getElementById('ge-form-wrapper').classList.add("no-related-suggestions");
      }
    }
    else {
      if (document.getElementById('ge-form-wrapper').classList.contains("no-related-suggestions")) {
        document.getElementById('ge-form-wrapper').classList.remove("no-related-suggestions");
      }
    }
  }

  SelectRecent(selectedRecent){
    this.props.SelectRecent(selectedRecent);
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.toggleSearchOverlayHeight(true);
    }
  }

  SelectPopularSearch = (event) => {
    this.props.SelectPopularSearch(event.currentTarget.textContent);
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.toggleSearchOverlayHeight(true);
    }
  }

  SelectQuicklinks = (event) => {
    window.xprops.props.Openlinks(event.currentTarget.attributes['data-link-src'].value);
  }

  getQuicklinksdata() {
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.popularsearch = aviationPopularSearches;
      this.setState({hits: null});
    }
    else {
      fetch("/related")
      .then(response => response.json())
      .then(data => {
        if (Object.keys(data).length > 0){
          this.quicklinks = data.data[0].attributes.quicklinks;
          this.popularsearch = data.data[0].attributes.popularsearch;
          this.setState({hits: null});
        }
      });
    }
  }

  render() {

    if (this.quicklinks.length >0){
      quickLinks =  this.quicklinks.map((item, i) => (
        <li key={i}>
          <span onClick={this.SelectQuicklinks} data-link-src= {item.uri}>{item.title}</span>
        </li>
      ));
    }

    if (this.popularsearch.length >0){
      popularsearch =  this.popularsearch.map((item, i) => (
        <li key={i} >
          <span onClick={this.SelectPopularSearch}>{item}</span>
        </li>
      ));
    }

    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.toggleSearchOverlayHeight(false);

      return(
        <div className="row"  style={{"height" : InitialHeight}} >
        {this.props.hideRecent===false &&
          (
            <React.Fragment>
              <div id = "related-filler-container" className = "col-lg-2 col-md-2 d-md-block d-none"></div>
              <GERelatedSearchResultsAviation
                SelectRecent={this.SelectRecent.bind(this)} />
              <div id="popular-search-container" className="col-lg-4 col-md-4 col-12 px-1">
                <div className="related-search">{ this.state.popularSearchesLabel }</div>
                <div className="related-search-list">
                  <ul>
                    {popularsearch}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          )
        }

        { this.props.hideRecent===true &&
          (
            <React.Fragment>
              <div id="popular-search-container" className="col-lg-4 col-md-4 col-12 px-1">
                <div className="related-search">{ this.state.popularSearchesLabel }</div>
                <div className="related-search-list">
                  <ul>
                    {popularsearch}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          )
        }
        </div>
      );

    }
    else {
      return(
        <div className="row"  style={{"height" : InitialHeight}} >
        {this.props.hideRecent===false &&
          (
            <React.Fragment>
              <GERelatedSearchResults
                SelectRecent={this.SelectRecent.bind(this)} />
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="related-search sh-2">{ this.state.popularSearchesLabel }</div>
                <div className="related-search-list body-1">
                  <ul>
                    {popularsearch}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="related-search sh-2">{ this.state.quicklinksLabel }</div>
                <div className="related-search-list-links body-1">
                  <ul>
                    {quickLinks}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          )
        }

        { this.props.hideRecent===true &&
          (
            <React.Fragment>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="related-search sh-2">{ this.state.popularSearchesLabel }</div>
                <div className="related-search-list body-1">
                  <ul>
                    {popularsearch}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="related-search sh-2">{ this.state.quicklinksLabel }</div>
                <div className="related-search-list-links body-1">
                  <ul>
                    {quickLinks}
                  </ul>
                </div>
              </div>
            </React.Fragment>
          )
        }
        </div>
      );
    }
  }
}

